var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100 py-2 px-1",class:{
		'product-card-small-carousel-wrapper-mobile': _vm.$b.mt,
		'product-card-small-carousel-wrapper': _vm.$b.d,
	}},[_c('v-card',{staticClass:"h100 d-flex flex-column base rounded-md overflow-hidden justify-space-between product-card",class:[_vm.$bem('product-card-small-carousel'), { cardBorder: _vm.cardBorder }],attrs:{"hover":!_vm.outOfStock ? true : false,"ripple":false},on:{"click":function($event){return _vm.cardClick()}}},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }],on:{"mouseover":function($event){_vm.firstImageHover = true}}},[_c('hook',{attrs:{"zone":"image-small-carousel"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":{
							'product-card-small-carousel__image--smallWidth': _vm.$b.d,
							'product-card-small-carousel__image--smallWidth-mobile': _vm.$b.mt,
						}}}),(_vm.secondImage && _vm.firstImageHover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","imgClass":{
								'product-card-small-carousel__image--smallWidth': _vm.$b.d,
								'product-card-small-carousel__image--smallWidth-mobile': _vm.$b.mt,
							}}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"event-icon-small-carousel"}},[_c('CollectionEventIcon',{class:[
							_vm.$bem('__event-icon'),
							{
								'product-card-small-carousel__event-icon--top': _vm.eventIconTop,
								'product-card-small-carousel__event-icon--right': _vm.eventIconRight,
							} ],attrs:{"product":_vm.product,"imgWidth":"25px"}})],1)],1)]),_c('div',{class:[_vm.$bem('__data-cont'), { 'no-stock': _vm.outOfStock }, 'd-flex flex-column px-3 h100']},[_c('hook',{style:({ order: _vm.elementOrder.name }),attrs:{"zone":"name-small-carousel"}},[_c('ProductCardText',{attrs:{"textClass":[
						_vm.$bem('__name', 'line-clamp-2 font-1 header'),
						_vm.nameClass,
						_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end' ],"text":_vm.product.name}})],1),(_vm.brand)?_c('hook',{style:({ order: _vm.elementOrder.brand }),attrs:{"zone":"brand-small-carousel"}},[_c('ProductCardText',{attrs:{"textClass":[
						_vm.$bem('__brand', 'font-0 line-clamp-1'),
						_vm.brandClass,
						_vm.alignment == 'left' ? 'text-start' : _vm.alignment == 'center' ? 'text-center' : 'text-end' ],"text":_vm.brand}})],1):_vm._e()],1),(_vm.showWishlist)?_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"wishlist-toggler-small-carousel"}},[_c('ProductWishlistToggler',{class:_vm.$bem('__wishlist-toggler'),staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }