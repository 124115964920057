<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	props: {
		elementOrder: Object,
	},
	data() {
		return {
			cardType: this.$srv('productCardType.name', 'Design6'),
		}
	},
	computed: {
		hasWishlist() {
			let cards = ['Design1', 'Design5', 'Design8']
			return cards.some((i) => i == this.cardType)
		},
		eventIconTop() {
			return this.cardType == 'Design3'
		},
		eventIconRight() {
			return this.cardType == 'Design7'
		},
		priceContainerClass() {
			if (this.cardType == 'Design1' || this.cardType == 'Design4' || this.cardType == 'Design6')
				return [
					this.$bem('__prev-price'),
					{ 'justify-center': this.alignment == 'center', 'justify-end': this.alignment == 'right' },
					'd-flex align-center flex-wrap',
				]
			else
				return [
					'd-flex align-center flex-wrap w100',
					{ 'justify-center': this.alignment == 'center', 'justify-end': this.alignment == 'right' },
				]
		},
		priceClass() {
			if (this.cardType == 'Design1' || this.cardType == 'Design4' || this.cardType == 'Design6')
				return [
					this.$bem('__price'),
					{
						'text-center': this.alignment == 'center',
						'text-end': this.alignment == 'right',
						'pb-1': !this.prevPrice,
					},
					this.$b.d ? 'font-3' : 'font-2',
					'price line-clamp-1',
				]
			else return [this.$bem('__price'), this.$b.m ? 'font-2' : 'font-3', 'price line-clamp-1']
		},
		prevPriceClass() {
			if (this.cardType == 'Design1' || this.cardType == 'Design4' || this.cardType == 'Design6')
				return 'font-0 grey--text'
			else if (this.cardType == 'Design2' || this.cardType == 'Design5')
				return ['grey--text mr-2 font-0', this.$bem('__prev-price')]
			else return ['grey--text ml-2 font-0', this.$bem('__prev-price')]
		},
		discountClass() {
			if (this.cardType == 'Design1' || this.cardType == 'Design4' || this.cardType == 'Design6')
				return [this.$bem('__discount'), 'd-inline-block discount font-0 ml-2']
			else if (this.cardType == 'Design2')
				return [this.$bem('__discount--topRight'), 'discount', this.$b.d ? 'pa-2 font-1' : 'pa-1 font-0']
			else if (this.cardType == 'Design3' || this.cardType == 'Design7')
				return [
					this.$bem('__discount'),
					'd-inline-block discount font-0',
					{
						'alignment-center': this.alignment == 'center',
						'alignment-right': this.alignment == 'right',
					},
				]
			else return [this.$bem('__discount--topLeft'), 'discount', this.$b.d ? 'pa-2 font-1' : 'pa-1 font-0']
		},

		priceElementsOrder() {
			if (this.cardType == 'Design2' || this.cardType == 'Design5') return { prevPrice: 1, price: 2 }
			else return { price: 1, prevPrice: 2 }
		},

		prevPriceGroup() {
			if (this.cardType == 'Design1' || this.cardType == 'Design4' || this.cardType == 'Design6')
				return true
			else return false
		},
	},
}
</script>

<template>
	<div class="h100" :class="{ 'sq-wrapper': $b.d, 'product-card-sq-wrapper-mobile': $b.mt }">
		<v-hover v-model="hover">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				:ripple="false"
				:class="[$bem('sq'), { cardBorder: cardBorder }]"
				class="h100 d-flex flex-column overflow-hidden justify-space-between base product-card"
			>
				<div :class="[$bem('__image-cont mx-2'), { 'no-stock': outOfStock }]">
					<hook zone="image-sq">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1.5"
							:imgClass="
								$b.d
									? 'sq__image--smallWidth'
									: $b.t
									? 'sq__image--smallWidth-tablet'
									: 'sq__image--smallWidth-mobile'
							"
						/>
						<div v-if="secondImage && firstImageHover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								aspect-ratio="1.5"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="
									$b.d
										? 'sq__image--smallWidth'
										: $b.t
										? 'sq__image--smallWidth-tablet'
										: 'sq__image--smallWidth-mobile'
								"
							/>
						</div>
					</hook>

					<CollectionEventIcon
						:product="product"
						:class="[
							$bem('__event-icon'),
							{
								'sq__event-icon--top': eventIconTop,
								'sq__event-icon--right': eventIconRight,
							},
						]"
						:imgWidth="$b.m ? '20px' : '25px'"
					/>
				</div>

				<div
					:class="[$bem('__data-cont'), 'd-flex flex-column mx-2 h100 pb-2', { 'no-stock': outOfStock }]"
				>
					<ProductCardPrice
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="priceContainerClass"
						:prevPriceClass="prevPriceClass"
						:priceClass="priceClass"
						:discountClass="discountClass"
						:prevPriceGroup="prevPriceGroup"
						:elementOrder="priceElementsOrder"
						:style="{ order: elementOrder.price }"
						sq
					/>

					<router-link
						class="card-anchor header"
						:to="productRoute"
						:style="{ order: elementOrder.name }"
					>
						<hook zone="name-sq">
							<ProductCardText
								:textClass="[
									$bem('__name'),
									'line-clamp-1 header font-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:text="product.name"
							/>
						</hook>
					</router-link>

					<hook zone="brand-sq" v-if="brand" :style="{ order: elementOrder.brand }">
						<ProductCardText
							:textClass="[
								$bem('__brand', 'font-0 line-clamp-1'),
								alignment == 'left'
									? 'text-start'
									: alignment == 'center'
									? 'text-center'
									: 'text-end',
							]"
							:text="brand"
						/>
					</hook>

					<hook zone="wishlist-toggler-sq" v-if="hasWishlist">
						<ProductWishlistToggler
							:productId="product.id"
							:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
							style="z-index: 1"
							:height="25"
							:width="25"
							iconSize="18px"
						/>
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
</template>

<style lang="scss" scoped>
.sq-wrapper {
	height: 240px;
	width: 224px;
}

.sq:nth-child(1) {
	order: var(--elementOrder-1, 1);
}
.sq:nth-child(2) {
	order: var(--elementOrder-2, 2);
}
.sq:nth-child(3) {
	order: var(--elementOrder-3, 3);
}

.sq {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	@media (max-width: 900px) {
		&__event-icon {
			bottom: -7px;
			left: -4px;
			width: 20px;

			&--top {
				top: 6px;
				bottom: unset;
			}

			&--right {
				top: 6px;
				bottom: unset;
				right: -4px;
				left: unset;
			}
		}
	}

	&__event-icon {
		position: absolute;
		bottom: -6px;
		left: 6px;
		z-index: 1;
		width: 25px;

		&--top {
			top: 6px;
			bottom: unset;
		}

		&--right {
			top: 6px;
			bottom: unset;
			right: 6px;
			left: unset;
		}
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
		width: 25px !important;
		height: 25px !important;
	}
}

.cardBorder {
	border: 1px solid #b2b2b2;
}

::v-deep .sq {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}
	&__discount {
		padding: 2px 5px;
		border-radius: 4px;

		&--topLeft {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			border-bottom-right-radius: 4px;
		}

		&--topRight {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			border-bottom-left-radius: 4px;
		}
	}

	&__prev-price,
	&__price {
		display: -webkit-box;
	}

	&__image--smallWidth {
		max-width: 9em;
		max-height: 10em;
		left: 50% !important;
		translate: -50%;
	}

	&__image--smallWidth-mobile {
		max-width: 5em;
		max-height: 6em;
		left: 50% !important;
		translate: -50%;
	}

	&__image--smallWidth-tablet {
		max-width: 14em;
		max-height: 17em;
		left: 50% !important;
		translate: -50%;
	}
}

.no-stock {
	opacity: 0.4;
}
</style>

