var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{class:_vm.verticalWrapperClasses},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[_vm.$bem('product-card-design2-vertical'), { cardBorder: _vm.cardBorder }, _vm.verticalCardClasses],style:({ height: _vm.btnHover && _vm.hover ? _vm.hoverForBtn : '100%' }),attrs:{"hover":!_vm.outOfStock ? true : false,"ripple":false,"elevation":"2"},on:{"click":_vm.cardClick,"mouseover":function($event){_vm.firstImageHover = true}}},[_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }]},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.firstImageHover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"src":_vm.secondImage,"width":"100%"}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"offer"}},[(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{class:_vm.$bem('__offer'),attrs:{"product":_vm.product}}):_vm._e()],1),_c('hook',{attrs:{"zone":"event-icon"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1)],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-3',
					{ 'no-stock': _vm.outOfStock, 'h100 justify-space-between': _vm.btn } ]},[_c('router-link',{staticClass:"card-anchor header",attrs:{"to":_vm.productRoute}},[_c('hook',{attrs:{"zone":"name"}},[_c('ProductCardText',{attrs:{"textClass":[
								_vm.$bem('__name'),
								'line-clamp-2 font-2 px-1 header mt-2',
								_vm.alignment == 'left'
									? 'text-start'
									: _vm.alignment == 'center'
									? 'text-center'
									: 'text-end' ],"text":_vm.product.name}})],1)],1),_c('hook',{attrs:{"zone":"brand"}},[_c('div',{class:_vm.$bem('__brand-wrapper')},[_c('ProductCardText',{attrs:{"textClass":[
								_vm.$bem('__brand'),
								'font-0 line-clamp-1 px-1',
								_vm.alignment == 'left'
									? 'text-start'
									: _vm.alignment == 'center'
									? 'text-center'
									: 'text-end' ],"conditional":!!_vm.brand,"text":_vm.brand}})],1)]),_c('hook',{attrs:{"zone":"attrs&financingTags"}},[_c('div',{class:_vm.$bem('__financing-wrapper')},[(_vm.financingTagHtml)?_c('div',{class:[
								_vm.$bem('__financing'),
								'font-0 line-clamp-1 px-1 primary rounded',
								{
									'mb-2': !_vm.hasTextTag,
									'alignment-center': _vm.alignment == 'center',
									'alignment-right': _vm.alignment == 'right',
								} ],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}}):_vm._e()])]),_c('hook',{attrs:{"zone":"textTags"}},[_c('ProductCardText',{attrs:{"textClass":[
							_vm.$bem('__text-tags'),
							'font-0 line-clamp-1 px-1 primary rounded mb-2',
							{
								'alignment-center': _vm.alignment == 'center',
								'alignment-right mt-1': _vm.alignment == 'right',
							} ],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						'd-flex align-center line-clamp-1 px-1',
						{ 'justify-center': _vm.alignment == 'center', 'justify-end': _vm.alignment == 'right' } ],"prevPriceClass":[_vm.$bem('__prev-price'), 'grey--text mr-2 font-0'],"priceClass":[_vm.$bem('__price'), 'price font-4 line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2'],"elementOrder":{ prevPrice: 1, price: 2 }}}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),(_vm.hasBtn)?_c('div',{staticClass:"pa-3",class:{
						btnHoverVertical: _vm.btnHover,
						'btnHoverVertical--show': _vm.btnHover && _vm.hover,
						z1: _vm.product.hasUniqueVariant && !_vm.product.info.customizationKey,
					}},[_c('hook',{attrs:{"zone":"btn"}},[_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")])],1)],1):_vm._e()],1)])],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onLinkBlurMobile),expression:"onLinkBlurMobile"}],staticClass:"w100"},[_c('v-card',{class:[_vm.$bem('product-card-design2-horizontal'), { cardBorder: _vm.cardBorder }, _vm.horizontalCardClasses],attrs:{"ripple":false},on:{"click":_vm.cardClick}},[_c('div',{class:[
				_vm.$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
				{ 'no-stock': _vm.outOfStock } ]},[_c('hook',{attrs:{"zone":"imageHorizontal"}},[_c('Media',{class:_vm.$bem('__main-image', 'px-1'),attrs:{"src":_vm.mainImage,"width":"100%","imgClass":"product-card-design2-horizontal__image--smallWidth"}})],1),_c('hook',{attrs:{"zone":"offerHorizontal"}},[(_vm.hasLimitedTimeOffer)?_c('LimitedTimeOffer',{staticClass:"mx-2 rounded line-clamp-1",staticStyle:{"width":"inherit","padding":"0 4px !important"},attrs:{"product":_vm.product,"textAlign":"start"}}):_vm._e()],1)],1),_c('div',{staticClass:"col col-7 py-0 pl-1 d-flex flex-column justify-center",class:[_vm.$bem('__data-cont'), { 'no-stock': _vm.outOfStock }]},[_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var navigate = ref.navigate;
			var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){return _vm.onLinkClickMobile(navigate, $event)}}},[_c('hook',{attrs:{"zone":"nameHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__name'), 'line-clamp-2 font-3 header', { 'mt-3': _vm.hasBtn }],"text":_vm.product.name}})],1)],1)]}}])}),_c('hook',{attrs:{"zone":"brand"}},[_c('div',{class:_vm.$bem('__brand-wrapper')},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__brand'), 'font-0 line-clamp-1'],"conditional":!!_vm.brand,"text":_vm.brand}})],1)]),_c('hook',{attrs:{"zone":"attrs&financingTagsHorizontal"}},[_c('div',{class:_vm.$bem('__financing-wrapper')},[(_vm.financingTagHtml)?_c('div',{class:[
							_vm.$bem('__financing'),
							'font-0 line-clamp-1 px-1 primary rounded',
							{
								'mb-1': !_vm.hasTextTag && _vm.discountPct,
							} ],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}}):_vm._e()])]),_c('hook',{attrs:{"zone":"textTagsHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded'],"conditional":_vm.hasTextTag,"text":_vm.textTag}})],1),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":['d-flex align-center line-clamp-1', { 'pb-2': !_vm.hasBtn }],"prevPriceClass":['grey--text font-1 mr-1', _vm.$bem('__prev-price')],"priceClass":[_vm.$bem('__price'), 'price font-6 line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2'],"horizontalCard":"","elementOrder":{ prevPrice: 1, price: 2 }}}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),(_vm.hasBtn)?_c('div',{class:{
					btnHoverHorizontal: _vm.btnHover,
					'btnHoverHorizontal--show': _vm.btnHover && _vm.showBtnHoverMobile,
					z1: _vm.product.hasUniqueVariant && !_vm.product.info.customizationKey,
				}},[_c('hook',{attrs:{"zone":"btn"}},[_c('v-btn',{staticClass:"cta w100 rounded-md",attrs:{"disabled":_vm.buttonText == 'Sin stock',"loading":_vm.btnIsLoading},on:{"click":_vm.btnClicked}},[_vm._v(" "+_vm._s(_vm._f("lang")(_vm.buttonText))+" ")])],1)],1):_vm._e(),_c('hook',{attrs:{"zone":"event-iconHorizontal"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product,"imgWidth":"25px"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }